@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap");

/*
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
*/

/* MAIN COLOR #087f5b MAIN TINT #099268
   GRAY COLOR #343a40 GRAY TINT #495057
 */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* ------------------------ */
/* GENERAL STYLES */
/* ------------------------ */
body {
  font-family: "Inter", sans-serif;
  color: #343a40;
  /* border-bottom: 8px solid #087f5b; */
  border-bottom: 8px solid #494bb4;
}

header {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 80px;
  padding: 0 20px;
}

.logo {
  height: 50px;
  display: flex;
  flex-direction: row;
}

.menu-toggle {
  display: none;
  cursor: pointer;
}

.menu-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: #333;
  transition: all 0.3s ease-in-out;
}

.bullet-points li {
  padding: 1rem;
  font-weight: 400;
  font-size: 1.2rem;
}

/* ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
} */

.show {
  visibility: visible;
}

.hide {
  visibility: visible;
}

.dropdown {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

li {
  margin: 0 10px;
}

a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}

a:hover {
  background-color: #f5f5f5;
}

.hero {
  /* background-image: url('hero-image.jpg'); */
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  /* grid-auto-flow: column; */
}

.hero-text {
  color: #000;
  text-align: center;
  padding-right: 1.2rem;
}

.logoText {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.logoText2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 300;
  color: #494bb4;
}

h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #454853;
}

.btn {
  display: inline-block;
  /* color: #333; */
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  background-color: #4caf50;
  color: #fff;
  /* padding: 10px 20px; */
  border-radius: 5px;
  /* font-size: 1.2rem; */
  cursor: pointer;
}

.upper {
  text-transform: uppercase;
}

.btn:hover {
  background-color: #333;
  color: #fff;
  cursor: pointer;
}

.login {
  background-color: #f7f7f7;
  padding: 50px 0;
  padding-right: 10px;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.filter-container {
  display: flex;
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.container-how {
  /* max-width: 600px; */
  margin-top: 5rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: left;
}

h2 {
  margin-bottom: 30px;
  font-size: 2.5rem;
  font-weight: 500;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.form-group-alt {
  display: flex;
  margin-bottom: 20px;
  /* width: 100%; */
}

label {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: left;
}

/* input[type="text"],
input[type="password"],
input[type="email"] {
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
} */

.input-field {
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
}

.check-field {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 1.4rem;
}

.fld-error {
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  font-size: 1.2rem;
  box-shadow: 0 2px 5px red;
}

.large {
  font-size: 1.2rem;
}

.small {
  font-size: 1rem;
}

.btn:hover {
  background-color: #3e8e41;
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (height 56.25% of width) */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lead-body {
  margin: 0;
  padding: 20px;
}

.lead-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.lead-card {
  background-color: #ffffff;
  padding-left: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.lead-card:hover {
  transform: translateY(-5px);
}

.lead-card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333333;
}

.lead-card-content {
  font-size: 1.2rem;
  font-weight: 500;
  color: #666666;
  line-height: 1.6;
}

.lead-card-link {
  display: block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.lead-card-link:hover {
  text-decoration: underline;
}

.month-calendar {
  /* background-color: black; */
  font-size: 40px;
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(4, 1fr);
  row-gap: 1px;
  column-gap: 1px;
  padding-top: 30px;

  /* justify-content: center;
    align-content: center;

    height: 900px;
    width: 1000px; */

  /*
        Align items inside Cells 
    */
  /* align-items: center; */
  /* justify-items: center; */
}

.month-day {
  background-color: #fff;
  height: 100px;
  border: 1px solid black;
}

.month-title {
  background-color: #fff;
  /* height: 50px; */
  grid-column: 1 / -1;
  /* grid-column: 1 / span 7; */

  align-self: center;
  padding-top: 50px;
  /* justify-self: center; */
}

.month-buttons {
  display: flex;
  column-gap: 10px;
  float: right;
}

/* Card layout specific classes */

.card-container {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.card {
  flex: 1;
  max-width: 300px;
  /* justify-content: space-around; */
  background-color: #fff;
  /* flex-flow: row wrap; */
  /* align-items: stretch; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  border-radius: 8px;
}

.float-div {
  position: fixed; /* Fixes the div to the viewport */
  bottom: 30px; /* Distance from the bottom edge of the viewport */
  right: 30px; /* Distance from the right edge of the viewport */
}

.float-div:hover {
  cursor: pointer;
}

.card h2 {
  padding: 0 0.5rem 0 0.5rem;
  margin-bottom: 0;
  font-size: 1.5rem;
}

.card:hover {
  transform: translateY(-5px);
}

.card-header {
  /* max-height: 100px; */
  /* flex-grow: 1; */

  padding: 0.5rem;
}

.green {
  background-color: #00e906;
  background-image: linear-gradient(160deg, #00e906 0%, #80d0a1 100%);
}

.red {
  background-color: #ff0000;
  background-image: linear-gradient(160deg, #ff0000 0%, #ff6565 100%);
}

.yellow {
  background-color: #e3ed0f;
  background-image: linear-gradient(160deg, #e3ed0f 0%, #f8f93d 100%);
}

.blue {
  background-color: #00a3ff;
  background-image: linear-gradient(160deg, #00a3ff 0%, #3db5f9 100%);
}

.pink {
  background-color: #f800ff;
  background-image: linear-gradient(160deg, #f800ff 0%, #f448ff 100%);
}

.orange {
  background-color: #ff7200;
  background-image: linear-gradient(160deg, #ff7200 0%, #f98d31 100%);
}

.card-body {
  /* max-height: 100px; */
  /* flex-grow: 4; */
  padding: 0.5rem;
}

.fld-msg-list {
  list-style-type: disc;
  flex-direction: row;
}

ol li {
  text-align: left;
}

.infoMsg {
  font-size: 1rem;
  text-align: left;
  margin: 0;
}

.teamSection {
  margin: 1rem auto;
  width: 90vw;
  max-width: 1170px;
}

.country {
  display: flex;
  align-items: center;
  justify-content: center;
}

.country h4 {
  padding-left: 20px;
  padding-right: 20px;
}

.teams {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1rem auto;
  width: 90vw;
  max-width: 1170px;
  gap: 20px;
}

.team {
  display: grid;
  grid-template-columns: 3fr 1fr;
  width: 40vw;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem 2rem;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.leaders-title {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 1rem;
  width: 90vw;
  max-width: 1170px;
  gap: 20px;
}

.member {
  display: grid;
  grid-template-columns: 1fr;
  /* width: 40vw; */
  align-items: flex-start;
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.3rem 2rem;
}

.container-top {
  margin-top: 5rem;
}

.container-how {
  /* max-width: 600px; */
  margin-top: 5rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.leaders-data {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90vw;
  max-width: 1170px;
  gap: 20px;
  padding: 10px;
}

.team img {
  width: 50px;
  height: 35px;
  justify-self: left;
}

.thumsUp {
  font-size: 1.8rem;
  color: green;
}

.thumsUp:hover {
  cursor: pointer; /* Change the cursor to pointer when hovering over the icon */
}

.thumsDown {
  font-size: 1.8rem;
  color: red;
}

.thumsDown:hover {
  cursor: pointer; /* Change the cursor to pointer when hovering over the icon */
}

.group-header-region {
  margin-top: 7rem;
}

@media (max-width: 40rem) {
  /* CSS rules to apply when viewport width is 40rem or less */

  .teams {
    display: grid;
    grid-template-columns: 1fr;
    margin: 1rem auto;
    width: 90vw;
    max-width: 1170px;
    gap: 20px;
  }

  .team {
    display: grid;
    grid-template-columns: 3fr 1fr;
    width: 90vw;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem 2rem;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .hero {
    grid-template-columns: 1fr;
  }

  .hero-text {
    padding-top: 7rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .show {
    visibility: visible;
  }

  .hide {
    visibility: hidden;
  }

  .dropdown {
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    /* opacity: 0; */
    /* visibility: hidden; */
    transition: all 0.3s ease-in-out;
  }

  .active {
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
  }

  .team {
    display: grid;
    grid-template-columns: 3fr 1fr;
    width: 90vw;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem 2rem;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .inactive {
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  li {
    margin: 10px 0;
  }

  a {
    padding: 0;
  }
}
